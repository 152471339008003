import React, { FC } from 'react'
import { getTextColor } from 'config/themeOverride/getColor'
import { SiteMenu } from '../type'
import { Flex, Group, Text, Image, Stack, useMantineTheme } from '@mantine/core'
import { AnchorLink } from 'components/ui'
import { IconArrowRight } from '@tabler/icons-react'

type Props = {
  menus: SiteMenu[]
  active?: boolean
}

const SubMenus: FC<Props> = ({ menus, active }) => {
  const theme = useMantineTheme()

  return (
    <Stack spacing={0}>
      {menus.map((menu) => (
        <AnchorLink
          key={menu.name}
          href={menu.href!}
          passHref
          target={menu.target || '_self'}
          className="!no-underline px-sm py-sm hover:bg-gray-0"
        >
          <Flex justify="space-between" align="center" className="group cursor-pointer">
            <Group spacing={12}>
              {menu?.logo && (
                <Image
                  src={menu.logo}
                  width={32}
                  className={`${active ? 'opacity-100' : 'opacity-80 group-hover:opacity-100'} `}
                  alt={menu.name}
                />
              )}
              <Text color="gray.9" weight={600}>
                {menu.name}
              </Text>
            </Group>
            <IconArrowRight
              size={18}
              stroke={2}
              color={getTextColor('gray.9', theme)}
              className="mr-[4px] opacity-0 ease-in duration-200 transition-all group-hover:opacity-100 group-hover:translate-x-[4px]"
            />
          </Flex>
        </AnchorLink>
      ))}
    </Stack>
  )
}

export default SubMenus

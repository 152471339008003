import React, { FC, PropsWithChildren } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Button, Flex, Group, Stack, Text } from '@mantine/core'
import cls from 'classnames'

import SiteTitle from 'components/site/SiteTitle'
import { Signup } from 'config/constants'
import { useSmallerScreen } from 'hooks'

type Props = PropsWithChildren & {
  title: string
  description: string
  isIntersecting?: boolean
}

const FeatureHeader: FC<Props> = (props) => {
  const smallerScreen = useSmallerScreen()
  const { query } = useRouter()
  const { title, description, isIntersecting, children } = props

  return (
    <Flex
      gap={smallerScreen ? 32 : 0}
      direction={smallerScreen ? 'column' : 'row'}
      align="center"
      justify="space-between"
      className={cls(smallerScreen ? 'w-full' : 'w-[1250px]')}
    >
      <Stack spacing={0} px={smallerScreen ? 16 : 0}>
        <SiteTitle text={title} isIntersecting={isIntersecting} />
        <Text
          size={16}
          mt={smallerScreen ? 16 : 12}
          mb={smallerScreen ? 32 : 24}
          className={cls('transformSlideInBottomBase delay-150', {
            transformSlideInBottom: isIntersecting,
          })}
        >
          {description}
        </Text>
        <Group position={smallerScreen ? 'center' : 'left'}>
          <Link href={{ pathname: Signup, query }} passHref legacyBehavior>
            <Button
              variant="default"
              style={{
                width: smallerScreen ? 303 : 130,
                height: 48,
                fontSize: '14px',
              }}
            >
              免费试用
            </Button>
          </Link>
        </Group>
      </Stack>
      {children}
    </Flex>
  )
}

export default FeatureHeader

import {
  HelpCenter,
  Institute,
  Pricing,
  Templates,
  whyNotCemCase,
  whyNotDevCase,
  whyNotOverseasCase,
  whyNotSurveyCase,
} from 'config/constants'
import caixuanLogo from './images/caixuan.png'
import cktLogo from './images/ckt.png'
import geekRateLogo from './images/geekRate.png'

import all from './images/all.png'
import eclicktech from './images/eclicktech.png'
import jsjLogo from './images/jsj.png'
import noBookLogo from './images/nobook.png'
import tracupLogo from './images/tracup.png'
import uCloudLogo from './images/ucloud.png'
import { SiteMenu } from './type'

export const SiteMenus: SiteMenu[] = [
  {
    name: '客户案例',
    subMenu: [
      {
        name: '全部案例',
        href: 'https://howxm.com/institute/tag/cases/',
        logo: all.src,
      },
      {
        name: '易点天下',
        href: 'https://howxm.com/institute/cyberklick-case/',
        logo: eclicktech.src,
      },
      {
        name: '金数据',
        href: 'https://howxm.com/institute/jinshuju-case/',
        logo: jsjLogo.src,
      },
      {
        name: '创客贴',
        href: 'https://howxm.com/institute/chuangkit-case/',
        logo: cktLogo.src,
      },
      {
        name: 'UCloud',
        href: 'https://howxm.com/institute/ucloud-case/',
        logo: uCloudLogo.src,
      },
      {
        name: 'Tracup',
        href: 'https://howxm.com/institute/tracup-case/',
        logo: tracupLogo.src,
      },
      {
        name: '彩漩',
        href: 'https://howxm.com/institute/caixuan-case/',
        logo: caixuanLogo.src,
      },
      {
        name: 'Geek Rate',
        href: 'https://howxm.com/institute/geekrate-case/',
        logo: geekRateLogo.src,
      },
      {
        name: 'NOBOOK',
        href: 'https://howxm.com/institute/nobook-case/',
        logo: noBookLogo.src,
      },
    ],
  },
  { name: '场景模板', href: Templates },
  { name: '价格', href: Pricing },
  { name: '博客', href: Institute },
  { name: '帮助中心', href: HelpCenter, target: '_blank' },
  {
    name: '为什么选择浩客?',
    subMenu: [
      {
        name: '浩客 VS 表单问卷',
        href: whyNotSurveyCase,
      },
      {
        name: '浩客 VS 客户体验管理软件（CEM）',
        href: whyNotCemCase,
      },
      {
        name: '浩客 VS 海外产品',
        href: whyNotOverseasCase,
      },
      {
        name: '浩客 VS 自研弹窗',
        href: whyNotDevCase,
      },
    ],
  },
]

import { useState } from 'react'

export default function useActivatedMenus() {
  const [activatedMenus, setActivatedMenus] = useState<string[]>([])
  const toggleMenu = (menuName: string) => {
    const activated = getActivated(menuName)
    if (activated) {
      setActivatedMenus(activatedMenus.filter((menu) => menu != menuName))
    } else {
      setActivatedMenus(activatedMenus.concat(menuName))
    }
  }

  const getActivated = (menuName: string) => activatedMenus.includes(menuName)

  return {
    toggleMenu,
    getActivated,
  }
}

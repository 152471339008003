import { ContentContainer } from 'components/site'
import React from 'react'
import { Box, Button, Flex, Group, Image, Paper, Stack, Title } from '@mantine/core'
import Link from 'next/link'
import { useRouter } from 'next/router'
import girl1Avatar from 'assets/images/site/avatar/girl1.png'

import { Signup } from 'config/constants'
import { useSmallerScreen } from 'hooks'
import classes from './index.module.scss'

import bgImg from 'assets/images/site/bg.jpg'

const GoToSignup = () => {
  const { query } = useRouter()
  const smallerScreen = useSmallerScreen()
  return (
    <ContentContainer className="w-full">
      <Box className="w-full flex justify-center">
        <Paper
          className={classes.wrapper}
          sx={{
            backgroundImage: `url(${bgImg.src})`,
          }}
          px={smallerScreen ? 32 : 64}
          pt={smallerScreen ? 40 : 64}
          pb={smallerScreen ? 48 : 64}
          radius="md"
        >
          <Flex direction={smallerScreen ? 'column' : 'row'} justify="space-between" align="center">
            <Stack spacing={0}>
              <Image src={girl1Avatar.src} alt="avatar" width={100} height={100} />
              <Title size={smallerScreen ? 32 : 40} color="#F95215">
                心动了？
              </Title>
              {!smallerScreen && <Title size={40}>点击右侧按钮</Title>}
              <Title size={smallerScreen ? 32 : 40}>用浩客收集用户反馈，改善产品体验</Title>
            </Stack>
            <Group className={`${smallerScreen ? 'mt-[120px] px-lg' : 'md:self-end mt-[12px] md:mt-0'}`}>
              <Link href={{ pathname: Signup, query }} passHref legacyBehavior>
                <Button
                  component="a"
                  radius="md"
                  size="lg"
                  classNames={{ root: `${smallerScreen ? 'w-[254px]' : 'w-[270px]'}` }}
                >
                  免费注册
                </Button>
              </Link>
            </Group>
          </Flex>
        </Paper>
      </Box>
    </ContentContainer>
  )
}

export default GoToSignup

import React, { FC } from 'react'
import { Box, Flex, Image, Text } from '@mantine/core'
import cls from 'classnames'
import { useSmallerScreen } from 'hooks'
import { isArray } from 'lodash-es'
import girl3Avatar from 'assets/images/site/avatar/girl3.png'

type Position = 'left' | 'right'

type Props = {
  text: string | string[]
  position?: Position
  avatar?: any
  pcWidth?: number
  conversationClassName?: string
  avatarHidden?: boolean
}

const Conversation: FC<Props> = (props) => {
  const smallerScreen = useSmallerScreen()
  const {
    text,
    position = 'right',
    avatar = girl3Avatar,
    pcWidth = 384,
    conversationClassName,
    avatarHidden = false,
  } = props

  return (
    <Flex direction={`${position === 'right' ? 'row' : 'row-reverse'}`} justify="center">
      <Box
        className={cls(
          'bg-gray-9 p-[16px] rounded-[28px]',
          `${position === 'right' ? 'rounded-br-md' : 'rounded-bl-md'}`,
          conversationClassName
        )}
        w={smallerScreen ? 276 : pcWidth}
      >
        {isArray(text) ? (
          <>
            {text.map((item, index) => (
              <Text key={index} color="white" size={smallerScreen ? 'sm' : 'md'}>
                {item}
              </Text>
            ))}
          </>
        ) : (
          <Text color="white" size={smallerScreen ? 'sm' : 'md'}>
            {text}
          </Text>
        )}
      </Box>
      {!avatarHidden && (
        <Image
          className={`self-end ${smallerScreen ? ' mb-[-30px]' : ' mb-[-50px]'}`}
          src={avatar.src}
          width={smallerScreen ? 60 : 100}
          alt="avatar"
          height={smallerScreen ? 60 : 100}
        />
      )}
    </Flex>
  )
}

export default Conversation

import { useDisclosure } from '@mantine/hooks'
import React from 'react'
import HeaderBar from './HeaderBar'
import MobileMenu from './MobileMenu'
import { ResponsiveHead } from 'components/ui'

const Header = () => {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false)

  return (
    <>
      <HeaderBar drawerOpened={drawerOpened} drawerOnClick={toggleDrawer} />
      <ResponsiveHead />
      <MobileMenu opened={drawerOpened} onClose={closeDrawer} />
    </>
  )
}

export default Header

import { createStyles } from '@mantine/core'
import bgImg from './images/bg.jpg'

export const useStyles = createStyles((_theme) => ({
  container: {
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
  },

  aside: {
    width: '50vw',
    minHeight: '100vh',
    background: `radial-gradient(#EAEFF9 .9px, #F9FBFC .5px)`,
    backgroundSize: '10px 10px',
    position: 'relative',
  },

  center: {
    padding: '16px',
    background: `url(${bgImg.src}) center no-repeat`,
    backgroundSize: 'cover',
  },
}))

import { DistributionChannelGroup } from 'config/constants'
import React, { FC } from 'react'
import { Center, Group, useMantineTheme } from '@mantine/core'
import { getTextColor } from 'config/themeOverride/getColor'
import { Icon } from 'components/ui'

type ChannelProps = {
  channel: DistributionChannelGroup
  active: boolean
  onClick: (channel: DistributionChannelGroup) => void
}

const Channel: FC<ChannelProps> = ({ channel, active, onClick }) => {
  const theme = useMantineTheme()
  const iconName = channel === 'mobileApp' ? 'mobile4Site' : channel

  return (
    <Center
      sx={() => ({
        width: '40px',
        height: '32px',
        background: active ? 'white' : 'unset',
        border: `1px solid ${active ? '#E3E5E8' : 'transparent'}`,
        boxShadow: active ? '0 8px 12px rgba(0, 0, 0, 0.05)' : 'unset',
        cursor: 'pointer',
        borderRadius: '6px',
      })}
      onClick={() => onClick(channel)}
    >
      <Icon name={iconName as any} style={{ color: getTextColor('gray.9', theme), width: 24, height: 24 }} />
    </Center>
  )
}

type Props = {
  channels: DistributionChannelGroup[]
  channel: DistributionChannelGroup
  onClick: (channel: DistributionChannelGroup) => void
}

const ChannelSwitcher: FC<Props> = (props) => {
  const { channels, channel, onClick } = props

  return (
    <Group
      h={40}
      spacing={8}
      px={4}
      position="center"
      align="center"
      sx={() => ({
        background: '#F6F7F8',
        borderRadius: '8px',
      })}
    >
      {channels.map((item) => (
        <Channel key={item} channel={item} active={item === channel} onClick={onClick} />
      ))}
    </Group>
  )
}

export default ChannelSwitcher

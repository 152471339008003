import { PropsWithChildren } from 'react'
import cls from 'classnames'
import styles from './index.module.scss'

type Props = {
  className?: string
}
const GridBackground = ({ children, className = '' }: PropsWithChildren<Props>) => {
  return <div className={cls(styles.grid, className)}>{children}</div>
}

export default GridBackground

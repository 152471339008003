import cls from 'classnames'
import { FeatureBox } from 'components/site'
import React, { FC, ReactNode } from 'react'
import { Box, Flex, Stack, Text, Title } from '@mantine/core'

type Props = {
  title: ReactNode
  description: ReactNode[]
  direction?: 'row' | 'column'
  children?: ReactNode
  w?: string | number
  h?: string | number
  isIntersecting?: boolean
  color?: string
}

const FeatureCard: FC<Props> = (props) => {
  const { title, description, color, isIntersecting, w, h, direction = 'column', children } = props

  return (
    <FeatureBox color={color}>
      <Flex direction={direction} w={w} h={h} justify="space-between">
        <Stack spacing={8} className="pt-[32px] sm:pt-[64px] pl-[32px] sm:pl-[64px] pr-[32px] sm:pr-[0]">
          <Title
            weight={900}
            size={24}
            className={cls('transformSlideInBottomBase delay-150', {
              transformSlideInBottom: isIntersecting,
            })}
          >
            {title}
          </Title>
          {description.map((item, index) => (
            <Text
              size={16}
              weight={400}
              key={index}
              className={cls('transformSlideInBottomBase delay-150', {
                transformSlideInBottom: isIntersecting,
              })}
            >
              {item}
            </Text>
          ))}
        </Stack>
        <Box
          className={cls('transformSlideInBottomBaseForImg delay-300 self-end', {
            transformSlideInBottomForImg: isIntersecting,
          })}
        >
          {children}
        </Box>
      </Flex>
    </FeatureBox>
  )
}

export default FeatureCard

import React, { FC } from 'react'
import {
  Box,
  Burger,
  Container,
  Flex,
  Group,
  Header,
  HoverCard,
  MediaQuery,
  Text,
  useMantineTheme,
} from '@mantine/core'
import { useViewportSize, useWindowScroll } from '@mantine/hooks'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import { getTextColor } from 'config/themeOverride/getColor'
import { AnchorLink, Logo } from 'components/ui'
import { Index } from 'config/constants'
import { SiteMenus } from 'components/site/SiteLayout/Header/constant'
import SubMenus from 'components/site/SiteLayout/Header/SubMenus'
import useActivatedMenus from 'components/site/SiteLayout/Header/hooks/useActivatedMenus'
import LoginOrSignupButton from './loginOrSignupButton'

const getColorAlpha = (scrollDistance: number, viewportHeight: number) => {
  if (viewportHeight === 0) {
    return 0
  }
  if (scrollDistance >= viewportHeight) {
    return 1
  }
  return scrollDistance / viewportHeight
}

type Props = {
  drawerOpened: boolean
  drawerOnClick: () => void
}

const HeaderBar: FC<Props> = ({ drawerOpened, drawerOnClick }) => {
  const { toggleMenu, getActivated } = useActivatedMenus()
  const theme = useMantineTheme()
  const [{ y: scrollY }] = useWindowScroll()
  const { height: viewportHeight } = useViewportSize()
  const shadowAlpha = getColorAlpha(scrollY, viewportHeight)
  const backgroundAlpha = getColorAlpha(scrollY, 200)

  return (
    <Header
      height="60px"
      className="top-0 fixed"
      withBorder={false}
      style={{
        borderBottom: `1px solid rgba(227, 229, 232, ${shadowAlpha})`,
        boxShadow: `0px 1px 8px rgba(0, 0, 0, ${shadowAlpha * 0.05})`,
        backgroundColor: `rgba(255, 255, 255, ${backgroundAlpha})`,
      }}
    >
      <Container size={1440} className="h-full">
        <Group position="apart" className="h-full">
          <AnchorLink href={Index}>
            <Logo />
          </AnchorLink>
          <MediaQuery query={`(max-width: ${theme.breakpoints.xs})`} styles={{ display: 'none' }}>
            <Group>
              <Group mr={44} spacing={48}>
                {SiteMenus.map((menu, index) => {
                  const activated = getActivated(menu.name)
                  const menuLabel = (
                    <Text
                      className={'hover:text-[#212529] ' + (getActivated(menu.name) ? 'text-[#212529]' : '')}
                      color="gray.7"
                      weight={theme.headings.fontWeight}
                    >
                      {menu.name}
                    </Text>
                  )
                  return menu.subMenu ? (
                    <HoverCard
                      key={menu.name}
                      width={index === 0 ? 280 : 190}
                      shadow="md"
                      onClose={() => toggleMenu(menu.name)}
                      onOpen={() => toggleMenu(menu.name)}
                      radius="md"
                      withinPortal
                      zIndex={1005}
                    >
                      <HoverCard.Target>
                        <Flex align="center" className="cursor-pointer" gap={6}>
                          {menuLabel}
                          {activated ? (
                            <IconChevronUp size={16} stroke={2} color={getTextColor('gray.9', theme)} />
                          ) : (
                            <IconChevronDown size={16} stroke={2} color={getTextColor('gray.9', theme)} />
                          )}
                        </Flex>
                      </HoverCard.Target>
                      <HoverCard.Dropdown py="xs" px={0}>
                        <SubMenus menus={menu.subMenu} />
                      </HoverCard.Dropdown>
                    </HoverCard>
                  ) : (
                    <AnchorLink
                      key={menu.name}
                      href={{ pathname: menu.href }}
                      underline={false}
                      target={menu.target || '_self'}
                    >
                      {menuLabel}
                    </AnchorLink>
                  )
                })}
              </Group>
              <Box w={180}>
                <LoginOrSignupButton />
              </Box>
            </Group>
          </MediaQuery>
          <Burger size="sm" opened={drawerOpened} onClick={drawerOnClick} className="xs:hidden" />
        </Group>
      </Container>
    </Header>
  )
}

export default HeaderBar

import React, { PropsWithChildren } from 'react'
import { Anchor, Container, Flex } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import useTranslation from 'next-translate/useTranslation'

import { AnchorLink, Logo, ResponsiveHead } from 'components/ui'
import { BaiduTracking } from 'components/site'

import Aside from './Aside'
import { useStyles } from './styles'
import { SiteHowXMAppId } from 'config'
import useHowxmSDK from 'plugins/howxm/useHowxmSDK'

interface Props {
  hideAside?: boolean
  logoCenter?: boolean
}

const SessionLayout = ({ hideAside, logoCenter, children }: PropsWithChildren<Props>) => {
  const { classes } = useStyles()
  const matches = useMediaQuery('(min-width: 1000px)')
  useHowxmSDK(SiteHowXMAppId)
  const { t } = useTranslation('pageLogin')
  const showAside = !hideAside && matches

  return (
    <>
      <ResponsiveHead />
      <div className={classes.container}>
        <Container
          py="lg"
          sx={{ maxWidth: 'initial', width: showAside ? '50vw' : '100vw', display: 'flex', flexDirection: 'column' }}
        >
          <Flex
            align="center"
            justify={logoCenter ? 'center' : 'space-between'}
            pt={logoCenter ? 'sm' : undefined}
            px="xl"
          >
            <AnchorLink href={process.env.NEXT_PUBLIC_SITE_URL!}>
              <Logo />
            </AnchorLink>
          </Flex>
          {children}
        </Container>
        {showAside && <Aside />}
      </div>
      <BaiduTracking />
    </>
  )
}

export default SessionLayout

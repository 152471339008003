import React, { FC, PropsWithChildren } from 'react'
import { Box } from '@mantine/core'

type Props = PropsWithChildren & {
  className?: string
  color?: string
}

const FeatureBox: FC<Props> = (props) => {
  const { className = '', color = '#F6F8F9', children } = props

  return (
    <Box
      className={className}
      sx={() => ({
        background: color,
        border: '1px solid #E3E5E8',
        boxShadow: `0px 0px 0px 4px #F6F8F9`,
        borderRadius: '20px',
        overflow: 'hidden',
      })}
    >
      {children}
    </Box>
  )
}

export default FeatureBox

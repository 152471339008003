import { useMounted } from 'hooks'
import React from 'react'
import { Box, Button, Group } from '@mantine/core'
import Link from 'next/link'
import { Login, Signup } from 'config/constants'
import { useRouter } from 'next/router'
import appContext from 'service'

const LoginOrSignupButton = () => {
  const mounted = useMounted()
  const query = useRouter().query

  if (!mounted) {
    return <Box />
  }

  const hasLogin = appContext.getSystemToken()

  return (
    <Group position="right">
      <Link href={{ pathname: Login, query }} passHref legacyBehavior>
        <Button component="a" variant="default">
          {hasLogin ? '进入系统' : '登录'}
        </Button>
      </Link>
      {!hasLogin && (
        <Link href={{ pathname: Signup, query }} passHref legacyBehavior>
          <Button component="a">免费注册</Button>
        </Link>
      )}
    </Group>
  )
}
export default LoginOrSignupButton

import cls from 'classnames'
import React, { FC, PropsWithChildren } from 'react'
import { Box, Stack } from '@mantine/core'
import { ContentContainer, Footer, GoToSignup } from 'components/site'
import classes from './index.module.scss'
import bgImg from 'assets/images/site/footer-bg.jpg'
import Divider from 'components/ui/Divider'

type Props = PropsWithChildren & {
  className?: string
}

const FooterWrapper: FC<Props> = ({ className, children }) => {
  return (
    <Stack
      className={cls(classes.wrapper, className)}
      spacing={0}
      sx={{
        backgroundImage: `url(${bgImg.src})`,
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {children}
      <GoToSignup />
      <ContentContainer className="w-full">
        <Box className="mx-[53px]">
          <Divider mt={96} mb={48} />
        </Box>
      </ContentContainer>
      <Footer />
    </Stack>
  )
}

export default FooterWrapper

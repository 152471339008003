import { PropsWithChildren } from 'react'
import { Container } from '@mantine/core'
import cls from 'classnames'

type Props = {
  className?: string
}

const ContentContainer = ({ children, className }: PropsWithChildren<Props>) => {
  return (
    <Container className={cls(className, 'w-full sm:max-w-[1440px]')} p={0}>
      {children}
    </Container>
  )
}

export default ContentContainer

import cls from 'classnames'
import React, { FC, ReactNode } from 'react'
import { Title, TitleProps } from '@mantine/core'

type Props = TitleProps & {
  text: ReactNode
  isIntersecting?: boolean
}

const SiteTitle: FC<Props> = ({ text, isIntersecting, ...titleProps }) => {
  return (
    <Title
      ta="left"
      weight={900}
      {...titleProps}
      className={cls('xs:!text-[40px] !text-[32px] transformSlideInBottomBase delay-150', {
        transformSlideInBottom: isIntersecting,
      })}
    >
      {text}
    </Title>
  )
}

export default SiteTitle

import { Anchor, Button, Divider, Drawer, Flex, Group, Stack, Text, useMantineTheme } from '@mantine/core'
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react'
import { Logo } from 'components/ui'
import { getTextColor } from 'config/themeOverride/getColor'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, Fragment } from 'react'
import { Login, Signup } from 'config/constants'
import { SiteMenus } from '../constant'
import SubMenus from 'components/site/SiteLayout/Header/SubMenus'
import useActivatedMenus from 'components/site/SiteLayout/Header/hooks/useActivatedMenus'

type Props = {
  opened: boolean
  onClose: () => void
}
const MobileMenu: FC<Props> = ({ opened, onClose }) => {
  const theme = useMantineTheme()
  const { toggleMenu, getActivated } = useActivatedMenus()
  const query = useRouter().query

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      size="100%"
      padding="md"
      title={<Logo colorScheme="light" />}
      transitionProps={{ transition: 'fade' }}
      zIndex={1000000}
      styles={{
        content: { paddingTop: '14px !important' },
        close: { color: theme.colors.gray[9] },
      }}
    >
      <Divider color="gray.1" />
      <Stack spacing="xl" py="xl">
        {SiteMenus.map((menu) => {
          if (menu.subMenu) {
            const activated = getActivated(menu.name)
            return (
              <Fragment key={menu.name}>
                <Flex align="center" w="100%" onClick={() => toggleMenu(menu.name)} gap={6}>
                  <Text key={menu.name} weight={theme.headings.fontWeight}>
                    {menu.name}
                  </Text>
                  {activated ? (
                    <IconChevronRight size={16} stroke={2} color={getTextColor('gray.9', theme)} />
                  ) : (
                    <IconChevronDown size={16} stroke={2} color={getTextColor('gray.9', theme)} />
                  )}
                </Flex>
                {activated && <SubMenus menus={menu.subMenu} active />}
              </Fragment>
            )
          }
          return (
            <Text key={menu.name}>
              <Anchor
                weight={theme.headings.fontWeight}
                color="gray.9"
                href={menu.href}
                underline={false}
                target={menu.target}
              >
                {menu.name}
              </Anchor>
            </Text>
          )
        })}
      </Stack>
      <Divider mb="md" color="gray.1" />
      <Group position="center" grow pb="xl">
        <Link href={{ pathname: Login, query }} passHref legacyBehavior>
          <Button component="a" variant="default">
            登录
          </Button>
        </Link>
        <Link href={{ pathname: Signup, query }} passHref legacyBehavior>
          <Button component="a" size="sm">
            免费注册
          </Button>
        </Link>
      </Group>
    </Drawer>
  )
}

export default MobileMenu

import { BaiduAnalyticsId } from 'config'
import Script from 'next/script'
import React from 'react'

const BaiduTracking = () => {
  if (!BaiduAnalyticsId) {
    return null
  }
  return (
    <Script
      id="baidu-sdk"
      dangerouslySetInnerHTML={{
        __html: `
          var _hmt = _hmt || [];
          (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?${BaiduAnalyticsId}";
            var s = document.getElementsByTagName("script")[0]; 
            s.parentNode.insertBefore(hm, s);
          })();
          `,
      }}
    />
  )
}

export default BaiduTracking

import React from 'react'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { Center, Stack, Text, Title } from '@mantine/core'

import { useStyles } from './styles'
import ratingImg from './images/rating.png'

const Aside = () => {
  const { classes } = useStyles()
  const { t } = useTranslation('user')

  return (
    <div className={classes.aside}>
      <Center className={'h-full ' + classes.center}>
        <Stack align="center" justify="center" spacing={0}>
          <Title order={1} size={36} weight={900} mt="md" lh="46px" sx={{ fontFamily: 'Noto Sans SC' }}>
            {t('sessionLayout.slogan')}
          </Title>
          <Text mt="md" mb="xl" size={16} sx={{ fontFamily: 'Noto Sans SC' }}>
            {t('sessionLayout.desc')}
          </Text>
          <Image src={ratingImg} alt="" width={520} height={360} />
        </Stack>
      </Center>
    </div>
  )
}

export default Aside

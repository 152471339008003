import React, { PropsWithChildren } from 'react'
import Header from './Header'
import { SiteHowXMAppId } from 'config'
import useHowxmSDK from 'plugins/howxm/useHowxmSDK'
import { BaiduTracking } from 'components/site'

type Props = {}

const SiteLayout = ({ children }: PropsWithChildren<Props>) => {
  useHowxmSDK(SiteHowXMAppId)

  return (
    <>
      <Header />
      {children}
      <BaiduTracking />
    </>
  )
}

export default SiteLayout
